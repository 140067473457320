import { Link, useStaticQuery, graphql } from "gatsby";
import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import Button from "react-bootstrap/Button";
import BackgroundImage from "gatsby-background-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import LocalBusinessSchema from "../components/schema/localBusinessSchema";
import { Helmet } from "react-helmet";
import { Card } from "react-bootstrap";
import { GatsbyImage } from "gatsby-plugin-image";
import Supportedby from "../components/supportedby";
import { checkPropertiesForNull } from "../utils/objectHelper";
import { SafeHtmlParser } from "../components/safeHtmlParser";
import uuid4 from "uuid4";

const IndexPage = () => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					siteUrl
				}
			}
			pageData: wpPage(slug: { eq: "home" }) {
				homePageFieldGroups {
					bannerSection {
						heading
						description
						cta {
							title
							target
							url
						}

						backgroundImage {
							node {
								altText
								localFile {
									childImageSharp {
										fluid(quality: 100) {
											...GatsbyImageSharpFluid
										}
									}
								}
							}
						}
					}
					homeHowWeCanHelpSection {
						heading
						description
						cta {
							title
							target
							url
						}
						services {
							heading
							description
							cta {
								title
								target
								url
							}
							image {
								node {
									altText
									localFile {
										childImageSharp {
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
											original {
												width
												height
											}
										}
									}
								}
							}
						}
					}
					homeSupportedSection {
						heading
						imageGallery {
							nodes {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
										original {
											width
											height
										}
									}
								}
							}
						}
					}
					homeAwards {
						strapline
						heading
						imageGallery {
							nodes {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: FULL_WIDTH
											placeholder: BLURRED
										)
										original {
											width
											height
										}
									}
								}
							}
						}
					}
					homeSponsorsSection {
						heading
						sponsorItems {
							image {
								node {
									altText
									localFile {
										childImageSharp {
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
											original {
												width
												height
											}
										}
									}
								}
							}
							link {
								target
								title
								url
							}
						}
					}
					aboveFooterSection {
						heading
						description
						cta1 {
							title
							target
							url
						}
						cta2 {
							title
							target
							url
						}
					}
				}
				seoFields {
					title
					metaDescription
					opengraphTitle
					opengraphDescription
					localBusinessSchema
					image {
						node {
							sourceUrl
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
									original {
										width
										height
									}
								}
							}
						}
					}
				}
			}
		}
	`);

	const {
		pageData: { seoFields, homePageFieldGroups },
	} = data;

	const {
		bannerSection,
		homeHowWeCanHelpSection,
		homeSupportedSection,
		homeAwards,
		homeSponsorsSection,
		aboveFooterSection,
	} = homePageFieldGroups;

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
				<meta
					name="google-site-verification"
					content="MzMFcoX1u0IbNrX-cVBNbRlbnnI04lpLkmVjhxhRm3E"
				/>
			</Helmet>
			<LocalBusinessSchema />
			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: `${seoFields?.opengraphTitle}`,
					description: `${seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${seoFields?.image?.node.sourceUrl}`,
							width: `${seoFields?.image?.node.localFile.childImageSharp.original.width}`,
							height: `${seoFields?.image?.node.localFile.childImageSharp.original.height}`,
							alt: `${seoFields?.image?.node.altText}`,
						},
					],
				}}
			/>

			{bannerSection && !checkPropertiesForNull(bannerSection, ["heading"]) && (
				<BackgroundImage
					Tag="section"
					className="hero py-4 d-flex align-items-center"
					id="hero-home"
					fluid={
						bannerSection.backgroundImage.node?.localFile.childImageSharp.fluid
					}
				>
					<Container className="h-100">
						<Row className="align-items-center h-100">
							<Col>
								<Row className=" align-items-end justify-content-between g-4">
									<Col className="mb-4 mb-lg-0" xs={12} md={12} lg={6}>
										<div className="p-4  h-100" id="hero-box">
											<h1 className="text-primary" id="home-hero-headline">
												{bannerSection?.heading}
											</h1>
											<div className="my-4" id="home-hero-subheading">
												<SafeHtmlParser
													htmlContent={bannerSection?.description}
												/>
											</div>
											{bannerSection.cta && bannerSection.cta.url && (
												<Button
													variant="info"
													size="lg"
													as={Link}
													className="full-width-button"
													target={bannerSection.cta?.target ?? "_blank"}
													to={bannerSection.cta?.url}
												>
													{bannerSection.cta?.title}
												</Button>
											)}
										</div>
									</Col>
									<Col xs={12} md={12} lg={5}>
										<div className="p-4  bg-white">
											<div className=" d-flex align-items-center w-100">
												<div className="d-block w-100">
													<Row className=" justify-content-between g-5">
														{homeAwards.imageGallery.nodes.map((item, i) => (
															<Col
																xs={4}
																md={4}
																key={uuid4()}
																className="mb-4 mb-md-0"
															>
																<GatsbyImage
																	className="w-90 "
																	image={
																		item?.localFile.childImageSharp
																			.gatsbyImageData
																	}
																	alt={item?.altText}
																/>
															</Col>
														))}
													</Row>
													<p
														style={{ fontStyle: "italic", fontSize: "90%" }}
														className="mt-md-4 mt-2 pb-0 mb-0"
													>
														{homeAwards.strapline}
													</p>
												</div>
											</div>
										</div>
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				</BackgroundImage>
			)}
			{homeHowWeCanHelpSection &&
				!checkPropertiesForNull(homeHowWeCanHelpSection, ["heading"]) && (
					<section id="introduction" className="py-5">
						<Container>
							<Row>
								<Col>
									<h2 className="text-primary">
										{homeHowWeCanHelpSection?.heading}
									</h2>
									<hr className="w-25 text-left bg-info ml-0" />
								</Col>
							</Row>
							<Row>
								<Col>
									<SafeHtmlParser
										htmlContent={homeHowWeCanHelpSection?.description}
									/>
								</Col>
							</Row>

							{homeHowWeCanHelpSection.services &&
								homeHowWeCanHelpSection.services.length > 0 && (
									<Row className="pt-5 justify-content-center service-card-wrapper">
										{homeHowWeCanHelpSection.services.map((item) => (
											<Col xs={12} md={6} className="h-100 my-5" key={uuid4()}>
												<Card className="border-0 service-card h-100">
													<GatsbyImage
														image={
															item.image.node?.localFile.childImageSharp
																.gatsbyImageData
														}
														alt={item.image.node?.altText}
													/>
													<Card.Title className="pt-3">
														<h4 className="text-primary">{item?.heading}</h4>
													</Card.Title>
													<Card.Body className="py-0">
														<div className="text-muted">
															<SafeHtmlParser htmlContent={item?.description} />
														</div>
													</Card.Body>
													<Card.Footer className="border-0 bg-white">
														{item.cta && item.cta.url && (
															<Link
																className="text-info font-weight-bold"
																to={item.cta?.url}
															>
																{item.cta?.title}
															</Link>
														)}
													</Card.Footer>
												</Card>
											</Col>
										))}
									</Row>
								)}
							{homeHowWeCanHelpSection.cta &&
								homeHowWeCanHelpSection.cta.url && (
									<Row className="text-center">
										<Col>
											<Button
												variant="primary"
												as={Link}
												size="lg"
												to={homeHowWeCanHelpSection.cta?.url}
												target={homeHowWeCanHelpSection.cta?.target ?? "_blank"}
											>
												{homeHowWeCanHelpSection.cta?.title}
											</Button>
										</Col>
									</Row>
								)}
						</Container>
					</section>
				)}
			{/* <section className="bg-secondary py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-white">Our Upcoming Events</h2>
							<hr className="w-25 ml-0 bg-white" />
						</Col>
					</Row>
					<EventItem
						calendar={true}
						color="text-white"
						name="Open Office Evening "
						when="23rd March | 5-8pm"
						where="7 Hyde Gardens, Eastbourne BN21 4PN"
						btnText="Contact us"
						eventImage={data.coffeeMorning2.childImageSharp.gatsbyImageData}
						link="/contact-us"
					/>
					<EventItem
						calendar={true}
						color="text-white"
						link="/contact-us"
						name="Fundraising Dinner"
						when="17th May 2023 | 7pm"
						where="La Locanda del Duca, Cornfield Terrace"
						btnText="Contact us"
						eventImage={data.coffeeMorning.childImageSharp.gatsbyImageData}
					/>
				</Container>
                </section> */}
			<Supportedby
				title={homeSupportedSection?.heading}
				supportbyImages={homeSupportedSection?.imageGallery?.nodes}
				sponsorTitle={homeSponsorsSection?.heading}
				sponsorLogos={homeSponsorsSection?.sponsorItems}
			/>

			{aboveFooterSection &&
				!checkPropertiesForNull(aboveFooterSection, ["heading"]) && (
					<section className="py-5" id="get-involved">
						<Container>
							<Row>
								<Col>
									<h2 className="text-primary">
										{aboveFooterSection?.heading}
									</h2>
									<hr className="w-25 text-left bg-info ml-0" />
								</Col>
							</Row>
							<Row>
								<Col>
									<SafeHtmlParser
										htmlContent={aboveFooterSection?.description}
									/>
								</Col>
							</Row>
							<Row className="pt-3">
								{aboveFooterSection.cta1 && aboveFooterSection.cta1.url && (
									<Col xs={12} md={6} lg={3}>
										<Button
											as={Link}
											variant="primary"
											size="lg"
											to={aboveFooterSection.cta1?.url}
											className="mr-lg-4 mb-4"
											target={aboveFooterSection.cta1?.target ?? "_blank"}
										>
											{aboveFooterSection.cta1?.title}
										</Button>
									</Col>
								)}
								{aboveFooterSection.cta2 && aboveFooterSection.cta2.url && (
									<Col xs={12} md={6} lg={3}>
										<Button
											variant="secondary"
											size="lg"
											href={aboveFooterSection.cta2?.url}
											target={aboveFooterSection.cta2?.target ?? "_blank"}
											className=""
										>
											{aboveFooterSection.cta2?.title}
										</Button>
									</Col>
								)}
							</Row>
						</Container>
					</section>
				)}
		</Layout>
	);
};

export default IndexPage;
