import React from "react";
import { Helmet } from "react-helmet";

const LocalBusinessSchema = () => {
	// const data = useStaticQuery(
	//     graphql`
	//       query {
	//         jeffVanImg: file(relativePath: { eq: "jeff-with-van.png" }) {
	//           publicURL
	//         }
	//         schemaLogo: file(relativePath: { eq: "logo.png" }) {
	//           publicURL
	//         }
	//       }
	//     `
	//   );

	const localBusinessSchema = {
		"@context": "https://schema.org",
		"@type": "Local Business",
		"@id": "https://example.example.co.uk",
		name: "Example",
		legalName: "Example Ltd",
		description: "",
		url: "https://www.example.co.uk",
		priceRange: "$$",
		areaServed: [
			{
				"@type": "City",
				name: "Eastbourne",
				sameAs: "https://en.wikipedia.org/wiki/Eastbourne",
			},
		],
		email: "info@example.co.uk",
		currenciesAccepted: "GBP",
		numberOfEmployees: "1",
		location: {
			"@type": "City",
			name: "Example",
			sameAs: "https://en.wikipedia.org/wiki/Example",
		},
		foundingLocation: {
			"@type": "City",
			name: "Eastbourne",
			sameAs: "https://en.wikipedia.org/wiki/Eastbourne",
		},
		slogan: "Example Slogan",
		telephone: "+441323123123",
		address: {
			"@type": "PostalAddress",
			addressLocality: "Eastbourne",
			addressRegion: "East Sussex",
			postalCode: "BN21 1AA",
			streetAddress: "123 Commercial Road",
			addressCountry: "United Kingdom",
		},
		geo: {
			"@type": "GeoCoordinates",
			latitude: "0",
			longitude: "0",
		},
		hasMap: "https://goo.gl/maps/",
		openingHours: "Mo,Tu,We,Th,Fr 09:00-17:00",
		openingHoursSpecification: [
			{
				"@type": "OpeningHoursSpecification",
				closes: "17:00",
				dayOfWeek: "http://schema.org/Monday",
				opens: "09:00",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "17:00",
				dayOfWeek: "http://schema.org/Tuesday",
				opens: "09:00",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "17:00",
				dayOfWeek: "http://schema.org/Wednesday",
				opens: "09:00",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "17:00",
				dayOfWeek: "http://schema.org/Thursday",
				opens: "09:00",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "17:00",
				dayOfWeek: "http://schema.org/Friday",
				opens: "09:00",
			},
		],
		founder: {
			"@type": "Person",
			name: "John Smith",
			id: "https://www.example.co.uk/who-we-are#johnSmith",
			url: "https://www.example.co.uk/who-we-are",
			description: "",
		},
		aggregateRating: {
			"@type": "AggregateRating",
			bestRating: "5",
			worstRating: "1",
			ratingValue: "4.9",
			reviewCount: "65",
			url: "https://www.example.co.uk/reviews",
		},
		contactPoint: {
			"@type": "ContactPoint",
			"@id": "https://www.example.co.uk/contact-us",
			url: "https://www.example.co.uk/contact-us",
			// "image": `${data.schemaLogo.publicURL}`,
			name: "Example",
			description: "",
			telephone: "+441323123123",
			contactType: "Example",
			availableLanguage: "en",
			areaServed: [
				{
					"@type": "City",
					name: "Eastbourne",
					sameAs: "https://en.wikipedia.org/wiki/Eastbourne",
				},
			],
		},
		logo: ``,
		image: ``,
		sameAs: [
			"https://www.facebook.com/",
			"https://twitter.com/",
			"https://www.instagram.com/",
			"https://www.linkedin.com/",
			"https://www.yell.com/",
		],
	};

	return (
		<Helmet>
			<script type="application/ld+json">
				{JSON.stringify(localBusinessSchema)}
			</script>
		</Helmet>
	);
};

export default LocalBusinessSchema;
